<script setup lang="ts">
import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

import { useEntitlementsStore, useFlagStore, useUserStore } from "@/stores";
import { GTabs, GToggleBar, PageLayout, type Tab } from "@/components";

const { t } = useI18n();
const flagStore = useFlagStore();
const { checkEntitlementEnabled } = useEntitlementsStore();
const {
  courseReportFlag,
  userReportFlag,
  detailedUsersReportFlag,
  courseMilestonesReportFlag,
  teamMembersReportFlag,
} = storeToRefs(flagStore);

const { isAuthorizedForRoute } = useUserStore();

const contentTabs: ComputedRef<Tab[]> = computed(() => {
  return [
    {
      label: t("dashboardMenu.reports_student"),
      id: "students",
      href: "students",
      isHidden: !(
        userReportFlag.value &&
        checkEntitlementEnabled("entReportIndividual") &&
        isAuthorizedForRoute("students")
      ),
    },
    {
      label: t("dashboardMenu.reports_course"),
      id: "courses",
      href: "courses",
      isHidden: !(
        courseReportFlag.value &&
        checkEntitlementEnabled("entReportCourse") &&
        isAuthorizedForRoute("courses")
      ),
    },
    {
      label: t("dashboardMenu.reports_log"),
      id: "detailed-users",
      href: "detailed-users",
      isHidden: !(
        detailedUsersReportFlag.value &&
        checkEntitlementEnabled("entReportActivities") &&
        isAuthorizedForRoute("detailed-users")
      ),
    },
    {
      label: t("dashboardMenu.reports_course_milestones"),
      id: "course-milestones",
      href: "course-milestones",
      isHidden: !(
        courseMilestonesReportFlag.value &&
        checkEntitlementEnabled("entReportMilestone") &&
        isAuthorizedForRoute("course-milestones")
      ),
    },
    {
      label: t("dashboardMenu.reports_group_member"),
      id: "team-members",
      href: "team-members",
      isHidden: !(
        teamMembersReportFlag.value &&
        checkEntitlementEnabled("entReportTeamMembers") &&
        isAuthorizedForRoute("team-members")
      ),
    },
  ];
});
</script>
<template>
  <GToggleBar :label="t('common.toggle_bar_label')" opt="in" />
  <PageLayout :title="t('reports.title')" :heading="t('reports.title')">
    <template #subHeading>
      {{ t("reports.sub_heading") }}
    </template>
    <GTabs :tabs="contentTabs" />
  </PageLayout>
</template>
