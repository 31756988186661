<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import {
  GButton,
  GDatePicker,
  GExportModal,
  GRadioGroup,
  GStateHandlerWrapper,
} from "@/components";
import { GTable } from "@/patterns";
import { useUsersReport } from "./useUsersReport";
import type { Filters, UsersTableRow } from "./types";
import { FilterPanel, UserStatusFilter } from "@/patterns";
import { reportInitialDateRange } from "../utils";
import TeamsSelect from "@/patterns/TeamsSelect/TeamsSelect.vue";
import { useFlagStore } from "@/stores";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const initialFilters: Filters = {
  search: "",
  status: "all",
  teams: [null],
  date: reportInitialDateRange,
};

const { t } = useI18n();
const isSecondaryFiltersOpen = ref(false);
const isExportModalOpen = ref(false);
const selectedRows = ref<UsersTableRow[]>([]);
const filters = ref<Filters>({ ...initialFilters });

const {
  isFetching,
  error,
  tableDefinition,
  data,
  hasReportTeamEntitlement,
  hasForeignUsername,
  usersPerStatus,
  isUserReporter,
} = useUsersReport(filters);

const exportTypeOptions = [
  {
    label: t("usersReport.export_modal_export_type_users"),
    value: "users",
  },
  {
    label: t("usersReport.export_modal_export_type_users_and_content_progress"),
    value: "usersAndContentProgress",
  },
];

const { userReportExportContentProgressFlag } = storeToRefs(useFlagStore());

const selectedExportType = ref<"users" | "usersAndContentProgress">("users");

const defaultSort = [{ desc: true, id: "studyTime" }];

const exportFileHeaders = computed(() => [
  t("reports.tbl_col_email"),
  t("reports.tbl_col_name"),
  ...(hasReportTeamEntitlement.value ? [t("reports.teams")] : []),
  t("reports.tbl_col_lesson_visits"),
  t("reports.tbl_col_study_time"),
  t("reports.tbl_col_course_started"),
  t("reports.tbl_col_course_completed"),
  t("reports.tbl_col_test_passed"),
  t("usersReport.tbl_col_has_good_scan"),
  t("reports.studentId"),
  ...(hasForeignUsername.value ? [t("reports.tbl_col_foreign_username")] : []),
]);

const toExportRows = computed(() => {
  return selectedRows.value.map((row) => {
    if (!hasReportTeamEntitlement.value) {
      delete row.teams;
    }
    return Object.values(row).map((v) => v.toString());
  });
});
</script>
<template>
  <GStateHandlerWrapper :isLoading="isFetching" :error="error">
    <GButton
      class="mb-4 ml-auto lg:px-12"
      @click="isExportModalOpen = true"
      :disabled="selectedRows.length === 0"
      data-testid="export-users-report-btn"
    >
      <template #prefixIcon><ArrowDownToLineIcon /></template>
      {{ t("common.export") }}
    </GButton>
    <FilterPanel
      v-model:search="filters.search"
      :isSecondaryFiltersVisible="isSecondaryFiltersOpen"
      @toggleSecondaryFilters="isSecondaryFiltersOpen = !isSecondaryFiltersOpen"
      @reset="filters = { ...initialFilters }"
    >
      <template #primaryFilters>
        <UserStatusFilter v-model="filters.status" :statuses="usersPerStatus" />
      </template>
      <template #secondaryFilters>
        <TeamsSelect
          v-if="hasReportTeamEntitlement"
          v-model="filters.teams"
          :restrictedToMyTeams="!isUserReporter"
        />
        <GDatePicker v-model="filters.date" :label="t('common.date')" />
      </template>
    </FilterPanel>
    <GTable
      :data="data"
      :columns="tableDefinition"
      @selected-list-change="(list) => (selectedRows = list)"
      :default-sort="defaultSort"
    >
      <template v-slot:teams="{ value }">
        <li v-for="team in value as string[]" :key="team" class="list-none">
          {{ team }}
        </li>
      </template>
      <template v-slot:report="{ row }">
        <GButton
          :href="`/admin-dashboard/reports/students/${row.studentId}?name=${row.name}`"
          size="small"
        >
          {{ t("reports.report") }}
        </GButton>
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <GExportModal
    :isOpen="isExportModalOpen"
    fileName="GH - students report"
    :data="{
      headers: exportFileHeaders,
      rows: toExportRows,
    }"
    @close="isExportModalOpen = false"
  >
    <GRadioGroup
      v-if="userReportExportContentProgressFlag"
      class="mb-2"
      v-model="selectedExportType"
      :options="exportTypeOptions"
      name="selectedExportType"
      ><template #label>
        {{ t("usersReport.export_modal_export_type") }}
      </template>
    </GRadioGroup>
  </GExportModal>
</template>
