import { redirectToOldDashboardPage } from "@/utils/redirect";
import { useLDFlag } from "launchdarkly-vue-client-sdk";
import { defineStore } from "pinia";
import { computed, watch, type Ref } from "vue";
import { useRoute } from "vue-router";

type OldNewPageMap = Record<string, { new: string | null; old: string | null }>;

export enum RouteFlag {
  CourseReport = "dash-course-report",
  CourseMilestonesReport = "course-milestones-report",
  GroupMembersReport = "group-members-report",
  UserReport = "user-report",
  DetailedUsersReport = "detailed-users-report",
  SmeAdminOnboarding = "sme-admin-onboarding",
  Resources = "dash-resources",
  AssessmentInsights = "assessment-insights",
  Lessons = "lessons",
  HandleLti11InLmsConnector = "handle-lti11-in-lms-connector",
  HandleRsInLmsConnector = "handle-rs-in-lms-connector",
  OScanReport = "o-scan-report-page",
  ManageUsers = "manage-users",
  ManageGroups = "manage-groups",
  ManageActionLog = "manage-action-log",
  ManageRoles = "manage-role-distribution",
}

export const useFlagStore = defineStore("flags", () => {
  const courseReportFlag = useLDFlag(RouteFlag.CourseReport, false);
  const courseMilestonesReportFlag = useLDFlag(
    "course-milestones-report",
    false,
  );
  const teamMembersReportFlag = useLDFlag(RouteFlag.GroupMembersReport, false);
  const userReportFlag = useLDFlag(RouteFlag.UserReport, false);
  const detailedUsersReportFlag = useLDFlag(
    RouteFlag.DetailedUsersReport,
    false,
  );
  const resourcesFlag = useLDFlag(RouteFlag.Resources, false);
  const assessmentsFlag = useLDFlag(RouteFlag.AssessmentInsights, false);

  const multiLangSCOExportFlag = useLDFlag(
    "dash-new-multi-lang-sco-export",
    false,
  );
  const smeOnboardingFlag = useLDFlag("sme-admin-onboarding", false);
  const learningLabzFlag = useLDFlag("learning-labz", false);
  const lti13ConfigPageFlag = useLDFlag("dash-lti13-config-page", false);
  const lessonsFlag = useLDFlag("lessons", false);
  const handleLti11InLmsConnectorFlag = useLDFlag(
    "handle-lti11-in-lms-connector",
    false,
  );
  const handleRsInLmsConnectorFlag = useLDFlag(
    "handle-rs-in-lms-connector",
    false,
  );
  const oScanReportPageFlag = useLDFlag("o-scan-report-page", false);
  const manageUsersPageFlag = useLDFlag("manage-users", false);
  const manageTeamsPageFlag = useLDFlag("manage-groups", false);
  const manageRolesPageFlag = useLDFlag("manage-role-distribution", false);
  const manageActionLogPageFlag = useLDFlag("manage-action-log", false);
  const reportsForeignUsernameFlag = useLDFlag(
    "reports-foreign-username",
    false,
  );
  const userReportExportContentProgressFlag = useLDFlag(
    "user-report-export-content-progress",
    false,
  );

  const routeMapFlag = useLDFlag(
    "old-new-dashboard-page-map",
    {} as OldNewPageMap,
  );

  const routeFlags = computed<Record<RouteFlag, Ref<boolean>>>(() => {
    return {
      [RouteFlag.CourseReport]: courseReportFlag,
      [RouteFlag.CourseMilestonesReport]: courseMilestonesReportFlag,
      [RouteFlag.GroupMembersReport]: teamMembersReportFlag,
      [RouteFlag.UserReport]: userReportFlag,
      [RouteFlag.DetailedUsersReport]: detailedUsersReportFlag,
      [RouteFlag.SmeAdminOnboarding]: smeOnboardingFlag,
      [RouteFlag.Resources]: resourcesFlag,
      [RouteFlag.AssessmentInsights]: assessmentsFlag,
      [RouteFlag.Lessons]: lessonsFlag,
      [RouteFlag.HandleLti11InLmsConnector]: handleLti11InLmsConnectorFlag,
      [RouteFlag.HandleRsInLmsConnector]: handleRsInLmsConnectorFlag,
      [RouteFlag.OScanReport]: oScanReportPageFlag,
      [RouteFlag.ManageUsers]: manageUsersPageFlag,
      [RouteFlag.ManageActionLog]: manageActionLogPageFlag,
      [RouteFlag.ManageGroups]: manageTeamsPageFlag,
      [RouteFlag.ManageRoles]: manageRolesPageFlag,
    };
  });

  const route = useRoute();
  watch(
    route,
    () => {
      if (route.meta?.flags?.release) {
        const flagValues = route.meta.flags.release.map(
          (flagName) => routeFlags.value[flagName]?.value,
        );
        const oldDashboardUrl = Object.values(routeMapFlag.value)?.find(
          (routeSpec) => routeSpec?.new === route.path,
        )?.old;

        if (flagValues.some((value) => value === false)) {
          redirectToOldDashboardPage(oldDashboardUrl);
        }
      }
    },
    { immediate: true },
  );

  return {
    routeFlags,
    routeMapFlag,
    resourcesFlag,
    courseReportFlag,
    courseMilestonesReportFlag,
    teamMembersReportFlag,
    userReportFlag,
    detailedUsersReportFlag,
    lti13ConfigPageFlag,
    smeOnboardingFlag,
    learningLabzFlag,
    multiLangSCOExportFlag,
    lessonsFlag,
    handleLti11InLmsConnectorFlag,
    handleRsInLmsConnectorFlag,
    oScanReportPageFlag,
    manageUsersPageFlag,
    manageActionLogPageFlag,
    manageTeamsPageFlag,
    manageRolesPageFlag,
    reportsForeignUsernameFlag,
    userReportExportContentProgressFlag,
  };
});
