<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { formatISO, startOfDay, sub } from "date-fns";
import { useFetch, useLocale } from "@/composables";
import {
  GButton,
  GFlag,
  GExportModal,
  GRadioGroup,
  GTooltip,
  GStateHandlerWrapper,
  GDatePicker,
} from "@/components";
import type { CourseReport } from "@/types/reports";
import { useCourseStore, useWebSessionStore } from "@/stores";
import { multiSelectFilter } from "@/composables/useFilter";
import { formatSecondsToHHMMSS } from "@/utils/misc";
import { getPayloadTags } from "../utils";
import {
  LanguageSelect,
  CourseCategoriesSelect,
  TeamsSelect,
  FilterPanel,
  GTable,
  type TableColumn,
} from "@/patterns";
import type { LanguageCode } from "@gh-dashboard/types";
import CourseStatusSelect from "../components/CourseStatusSelect.vue";

import NoPreview from "@/assets/images/no-preview.svg?url";
import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const { t } = useI18n();
const { locale } = useLocale();
const currentLang = locale.value.split("-")[0];

type ColumnAccessor =
  | "thumbnail"
  | "title"
  | "language"
  | "categoryId"
  | "activityVisits"
  | "courseStarted"
  | "courseCertificates"
  | "testCertificates"
  | "studyTime";

type CourseReportTableDataRow = {
  courseId: number;
  thumbnail: string;
  title: string;
  activityVisits: number;
  courseStarted: number;
  courseCertificates: number;
  testCertificates: number;
  studyTime: number;
  categoryId: number;
  containerId: number;
  language: string;
  subRows?: CourseReportTableDataRow[];
  status: number;
};

const courseReportTableDefinition: TableColumn<ColumnAccessor>[] = [
  {
    id: "report",
    headerLabel: t("reports.report"),
  },
  {
    id: "thumbnail",
    headerLabel: t("common.thumbnail"),
    accessor: "thumbnail",
    sortable: false,
    smallScreenPosition: "last",
  },
  {
    id: "language",
    headerLabel: t("common.language"),
    accessor: "language",
    visibleFrom: false,
    filterFnName: "equalsString",
    sortable: false,
  },
  {
    id: "categoryId",
    headerLabel: t("common.category"),
    accessor: "categoryId",
    visibleFrom: false,
    filterFnName: multiSelectFilter,
    sortable: false,
  },
  {
    id: "title",
    headerLabel: t("common.name"),
    accessor: "title",
    filterFnName: "includesString",
    smallScreenPosition: "center",
  },
  {
    id: "activityVisits",
    headerLabel: t("reports.tbl_col_lesson_visits"),
    accessor: "activityVisits",
  },
  {
    id: "courseStarted",
    headerLabel: t("reports.tbl_col_course_started"),
    accessor: "courseStarted",
  },
  {
    id: "courseCertificates",
    headerLabel: t("reports.tbl_col_course_completed"),
    accessor: "courseCertificates",
  },
  {
    id: "testCertificates",
    headerLabel: t("reports.tbl_col_test_passed"),
    accessor: "testCertificates",
  },
  {
    id: "studyTime",
    headerLabel: t("reports.tbl_col_study_time_hhmmss"),
    accessor: "studyTime",
  },
];

const defaultSort = [{ desc: true, id: "studyTime" }];

const selectedRows = ref<CourseReportTableDataRow[]>([]);

const courseStore = useCourseStore();
const { courses, isFetching: isFetchingCourses } = storeToRefs(courseStore);

const websessionStore = useWebSessionStore();

type Filters = {
  search: string;
  categories: number[];
  language: LanguageCode;
  teams: number[];
  status: number;
  date: [Date, Date];
};

const initialFilters: Filters = {
  search: "",
  categories: [null],
  language: null,
  teams: [null],
  status: null,
  date: [sub(startOfDay(Date.now()), { weeks: 1 }), new Date()],
};

const filters = ref<Filters>({ ...initialFilters });

const clientFilters = computed(() => [
  { id: "title", value: filters.value.search },
  { id: "categoryId", value: filters.value.categories },
]);

const postPayload = computed(() => {
  return {
    DateFrom: formatISO(filters.value.date[0]),
    DateTo: formatISO(filters.value.date[1]),
    LanguageCode: filters.value.language
      ? websessionStore.getLanguageCodeByLanguageRegion(
          filters.value.language,
        )
      : "",
    Tags: getPayloadTags(filters.value.teams),
    SearchFor: "",
    CourseThemeId: -1,
    CourseStatusId: filters.value.status ?? -1,
    isOptingInNewDashboard: true,
  };
});

const { data, isFetching, error } = useFetch("/api/ReportCourse/", {
  refetch: true,
})
  .post(postPayload)
  .json<CourseReport[]>();

const courseReports = computed(() => {
  const reports: CourseReportTableDataRow[] = [];
  if (data.value) {
    if (data.value?.length && courses.value?.length) {
      data.value?.forEach((courseReport: CourseReport) => {
        const course = courses.value.find(
          (c) => c.CourseId === courseReport.CourseId,
        );
        if (course) {
          reports.push({
            courseId: courseReport.CourseId,
            thumbnail: course.properties.image_landscape || "",
            title: course.properties.title || "",
            activityVisits: courseReport.Modules,
            courseStarted: courseReport.Starts,
            courseCertificates: courseReport.Completed,
            testCertificates: courseReport.Passed,
            studyTime: courseReport.ContentSeconds,
            categoryId: course.categoryId,
            containerId: course.CourseContainerId,
            language: course.Lang || "",
            status: course.PublicationStatus || -1,
          });
        }
      });
    }
  }
  return reports;
});

const groupedReports = computed(() => {
  const groupedReportsRecord = courseReports.value.reduce(
    (storage: Map<number, CourseReportTableDataRow[]>, report) => {
      const groupName = report.containerId;
      if (!storage.has(groupName)) storage.set(groupName, []);
      storage.get(groupName)?.push(report);
      return storage;
    },
    new Map(),
  );
  if (groupedReportsRecord) {
    const result = [];
    for (const group of groupedReportsRecord.values()) {
      const headerTitle = (
        group.find((report) => report.language.toLowerCase() === currentLang) ||
        group.find((report) => report.language === "EN") ||
        group[0]
      ).title;
      const header = {
        title: headerTitle,
        thumbnail: group[0].thumbnail,
        containerId: group[0].containerId,
        courseId: group[0].courseId,
        categoryId: group[0].categoryId,
        courseCertificates: 0,
        testCertificates: 0,
        courseStarted: 0,
        activityVisits: 0,
        studyTime: 0,
        subRows: group,
      };
      group.forEach((report) => {
        header.courseCertificates += report.courseCertificates;
        header.testCertificates += report.testCertificates;
        header.courseStarted += report.courseStarted;
        header.activityVisits += report.activityVisits;
        header.studyTime += report.studyTime;
      });
      result.push(header);
    }
    return result;
  }
  return [];
});

const isExportModalOpen = ref(false);

const selectedStructure = ref("flat");
const structureOptions = [
  { label: t("reports.structure_flat"), value: "flat" },
  { label: t("reports.structure_grouped"), value: "grouped" },
];

const courseReportsToExport = ref<{
  headers: string[];
  rows: (string | number)[][];
}>({
  headers: [],
  rows: [],
});
watch([isExportModalOpen, selectedStructure], () => {
  if (isExportModalOpen.value === false) return;
  if (selectedRows.value.length === 0) {
    courseReportsToExport.value.rows = [];
    return;
  }
  courseReportsToExport.value.headers = [
    t("common.name"),
    t("reports.tbl_col_lesson_visits"),
    t("reports.tbl_col_course_started"),
    t("reports.tbl_col_course_completed"),
    t("reports.tbl_col_test_passed"),
    t("reports.tbl_col_study_time"),
  ];
  if (selectedStructure.value === "flat") {
    const courses: CourseReportTableDataRow[] = [];
    selectedRows.value.forEach((groupRow) => {
      courses.push(
        ...courseReports.value.filter(
          (report) => report.containerId === groupRow.containerId,
        ),
      );
    });
    courses.sort((a, b) => b.studyTime - a.studyTime);
    courseReportsToExport.value.rows = courses.map((report) => {
      return [
        `${report.title} ${
          report.language && `(${report.language.toUpperCase()})`
        }`,
        report.activityVisits,
        report.courseStarted,
        report.courseCertificates,
        report.testCertificates,
        formatSecondsToHHMMSS(report.studyTime),
      ];
    });
  } else if (selectedStructure.value === "grouped") {
    courseReportsToExport.value.rows = selectedRows.value.map((report) => {
      return [
        report.title,
        report.activityVisits,
        report.courseStarted,
        report.courseCertificates,
        report.testCertificates,
        formatSecondsToHHMMSS(report.studyTime),
      ];
    });
  }
});

const getNoPreviewThumbnail = (e: Event) => {
  (e.target as HTMLImageElement).src = NoPreview;
  (e.target as HTMLImageElement).alt = t("table.no_preview");
};
const isSecondaryFiltersVisible = ref(false);
</script>
<template>
  <GStateHandlerWrapper
    :isLoading="isFetching || isFetchingCourses"
    :error="error"
  >
    <GButton
      class="mb-4 ml-auto lg:px-12"
      @click="isExportModalOpen = true"
      :disabled="selectedRows.length === 0"
    >
      <template #prefixIcon><ArrowDownToLineIcon /></template>
      {{ t("common.export") }}
    </GButton>
    <FilterPanel
      v-model:search="filters.search"
      :isSecondaryFiltersVisible="isSecondaryFiltersVisible"
      @toggleSecondaryFilters="
        isSecondaryFiltersVisible = !isSecondaryFiltersVisible
      "
    >
      <template #primaryFilters>
        <CourseCategoriesSelect v-model="filters.categories" />
        <LanguageSelect v-model="filters.language" :hasDefault="true" />
        <GDatePicker v-model="filters.date" :label="t('common.date')" />
      </template>
      <template #secondaryFilters>
        <CourseStatusSelect v-model="filters.status" />
        <TeamsSelect v-model="filters.teams" />
      </template>
    </FilterPanel>
    <GTable
      :data="groupedReports"
      :columns="courseReportTableDefinition"
      :filter="{
        columns: clientFilters,
      }"
      :default-sort="defaultSort"
      selectable="rows"
      :selectedList="selectedRows"
      @selectedListChange="
        (list) => selectedRows.splice(0, selectedRows.length, ...list)
      "
    >
      <template v-slot:thumbnail="cellProps">
        <img
          v-if="cellProps.row.subRows"
          :src="`/ghcc_media/${cellProps.value}`"
          @error="getNoPreviewThumbnail"
          class="h-8 w-14"
        />
        <span class="ml-5 flex gap-2" v-else
          ><GFlag
            :country-code="
              websessionStore.getCountryCodeByLanguageCode(
                cellProps.row.language,
              ) || 'gb'
            "
          />{{ cellProps.row.language.toUpperCase() }}</span
        >
      </template>
      <template v-slot:title="cellProps">
        <div class="w-[224px] truncate">
          <a
            :href="`/${locale}/courses/${
              cellProps.row.courseId
            }-${cellProps.row.title.toLowerCase().replace(/[^a-z0-9]/g, '-')}`"
            target="_blank"
            rel="noopener noreferrer"
            class="cursor-pointer border-b border-transparent hover:border-b hover:border-dotted hover:border-primary"
            :class="cellProps.row.subRows ? 'font-semibold' : 'ml-4 '"
          >
            {{ cellProps.value }}
          </a>
        </div>
      </template>
      <template v-slot:studyTime="cellProps">
        <span class="mr-4 block text-end">{{
          formatSecondsToHHMMSS(cellProps.value as number)
        }}</span>
      </template>
      <template v-slot:report="cellProps">
        <GButton
          v-if="cellProps.row.subRows"
          :href="`/admin-dashboard/reports/courses/${cellProps.row.containerId.toString()}`"
          size="small"
        >
          {{ t("reports.report") }}
        </GButton>
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <GExportModal
    :isOpen="isExportModalOpen"
    fileName="GH - course report overview"
    :data="courseReportsToExport"
    @close="isExportModalOpen = false"
  >
    <GRadioGroup
      v-model="selectedStructure"
      :options="structureOptions"
      name="selectedStructure"
      ><template #label>
        {{ t("reports.structure") }}
        <GTooltip
          class="w-48 bg-gray-900 p-2 text-white"
          placement="right-start"
        >
          <template #content>
            {{ t("reports.structure_tooltip") }}
          </template>
        </GTooltip>
      </template>
    </GRadioGroup>
  </GExportModal>
</template>
