import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatISO } from "date-fns";
import { storeToRefs } from "pinia";

import { useFetch } from "@/composables";
import type { FetchReportPayload } from "../types";
import { useEntitlementsStore, useFlagStore, useUserStore } from "@/stores";
import { userRoles } from "@/stores";
import type {
  ColumnAccessor,
  Filters,
  UsersReport,
  UsersTableRow,
} from "./types";
import { formatSecondsToHHMMSS } from "@/utils/misc";
import { createTableDef } from "@/patterns";
import {
  USER_STATUS,
  userStatusValueToKey,
  type UserStatusKey,
} from "@/constants/user";
import { getPayloadTags } from "../utils";

export const useUsersReport = (filters: Ref<Filters>) => {
  const { t } = useI18n();
  const flagStore = useFlagStore();
  const userStore = useUserStore();
  const { checkEntitlementEnabled } = useEntitlementsStore();
  const { currentUserRoles } = storeToRefs(userStore);

  const hasReportTeamEntitlement = computed((): boolean => {
    return checkEntitlementEnabled("entReportTeam");
  });

  const fetchUserReportPayload = computed<FetchReportPayload>(() => {
    const payload = {
      SearchFor: filters.value.search,
      DateFrom: filters.value.date[0]
        ? formatISO(filters.value.date[0], { representation: "date" })
        : null,
      DateTo: filters.value.date[1]
        ? formatISO(filters.value.date[1], { representation: "date" })
        : null,
      Tags: getPayloadTags(filters.value.teams),
      PersonStatusId:
        filters.value.status === "all" ? -1 : USER_STATUS[filters.value.status],
      CourseMilestoneId: 0,
      isOptingInNewDashboard: true,
    };
    return payload;
  });

  const { usersReport, isFetchingReport, error } = fetchUserReport(
    fetchUserReportPayload,
  );
  const hasForeignUsername = computed(
    () =>
      flagStore.reportsForeignUsernameFlag &&
      usersReport.value?.some((user) => user.ForeignUsername),
  );

  const usersPerStatus = computed<
    Record<Exclude<UserStatusKey, "created">, number>
  >(() => {
    const users = usersReport.value ?? [];
    const initialCounts = { invited: 0, active: 0, blocked: 0 };
    return users
      .filter((user) => user.StatusId !== USER_STATUS.created)
      .reduce((acc, user) => {
        const status = userStatusValueToKey[user.StatusId];
        acc[status]++;
        return acc;
      }, initialCounts);
  });

  const tableDefinition = computed(() =>
    createTableDef<ColumnAccessor>(
      [
        {
          id: "report",
          headerLabel: t("reports.report"),
        },
        {
          id: "email",
          headerLabel: t("reports.tbl_col_email"),
          accessor: "email",
          isGloballyFilterable: true,
        },
        {
          id: "foreignUsername",
          headerLabel: t("reports.tbl_col_foreign_username"),
          accessor: "foreignUsername",
        },
        {
          id: "name",
          headerLabel: t("reports.tbl_col_name"),
          accessor: "name",
        },
        {
          id: "teams",
          headerLabel: t("reports.teams"),
          accessor: "teams",
        },
        {
          id: "lessonVisitsCount",
          headerLabel: t("reports.tbl_col_lesson_visits"),
          accessor: "lessonVisitsCount",
        },
        {
          id: "studyTime",
          headerLabel: t("reports.tbl_col_study_time_hhmmss"),
          accessor: "studyTime",
        },
        {
          id: "coursesStartedCount",
          headerLabel: t("reports.tbl_col_course_started"),
          accessor: "coursesStartedCount",
        },
        {
          id: "coursesCertificateCount",
          headerLabel: t("reports.tbl_col_course_completed"),
          accessor: "coursesCertificateCount",
        },
        {
          id: "testsCertificateCount",
          headerLabel: t("reports.tbl_col_test_passed"),
          accessor: "testsCertificateCount",
        },
        {
          id: "hasGoodScan",
          headerLabel: t("usersReport.tbl_col_has_good_scan"),
          accessor: "hasGoodScan",
        },
        {
          id: "studentId",
          accessor: "studentId",
          visibleFrom: false,
        },
      ],
      {
        teams: !!hasReportTeamEntitlement.value,
        foreignUsername: hasForeignUsername.value,
      },
    ),
  );

  return {
    usersReport,
    error,
    isFetching: isFetchingReport,
    data: computed(() => mapUsersDataToTableData(usersReport.value)),
    tableDefinition,
    hasReportTeamEntitlement,
    hasForeignUsername,
    usersPerStatus,
    isUserReporter: computed(() =>
      currentUserRoles.value.includes(userRoles.reporter),
    ),
  };
};

const fetchUserReport = (payload: Ref<FetchReportPayload>) => {
  const {
    isFetching: isFetchingReport,
    error,
    data: usersReport,
  } = useFetch("api/ReportStudent", {
    refetch: true,
  })
    .post(payload)
    .json<UsersReport[]>();

  return { isFetchingReport, error, usersReport };
};

const mapUsersDataToTableData = (
  users: UsersReport[] | null,
): UsersTableRow[] => {
  if (!users) return [];

  return users
    .map((user) => ({
      email: user.Email,
      name: `${user.LastName}, ${user.FirstName} ${user.MidName}`,
      teams: user.Tags,
      lessonVisitsCount: user.ModuleCount,
      studyTime: formatSecondsToHHMMSS(user.ContentSeconds),
      coursesStartedCount: user.CoursesStarted,
      coursesCertificateCount: user.CoursesCompleted,
      testsCertificateCount: user.TestsPassed,
      hasGoodScan: user.GoodScanDone ? "✓" : "-",
      studentId: user.PersonId,
      foreignUsername: user.ForeignUsername,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};
