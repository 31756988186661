<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

import { PageLayout, GStateHandlerWrapper, GToggleBar } from "@/components";
import {
  GDropdownButtonTrigger,
  GDropdownIconButtonTrigger,
} from "@/components/GDropdown";
import GNameCard from "@/components/GNameCard/GNameCard.vue";
import ActionMenu from "./components/ActionMenu.vue";
import ActionBar from "./components/ActionBar.vue";
import StatusBadge from "./components/StatusBadge.vue";
import ModalRevokeInvitation from "./components/ModalRevokeInvitation.vue";
import ModalAnonymise from "./components/ModalAnonymise.vue";
import ModalBlock from "./components/ModalBlock.vue";
import ModalExport from "./components/ModalExport.vue";
import { useFetch } from "@/composables";

import {
  FilterPanel,
  RolesSelect,
  TeamsSelect,
  GTable,
  UserStatusFilter,
  type StatusFilterKey,
  type TableColumn,
} from "@/patterns";
import type { ColumnAccessor, UsersResponse } from "./types";
import useUserActionsStore from "./store";
import {
  USER_STATUS,
  userStatusValueToKey,
  type UserStatusKey,
} from "@/constants/user";
import EllipsisVerticalIcon from "@/assets/images/icons/ellipsis-vertical.svg";
type Filters = {
  search: string;
  teams: number[] | null;
  roles: string[];
  statusKey: StatusFilterKey;
};

const { t } = useI18n();
const userActionStore = useUserActionsStore();
const { selectedUsers, activeRow } = storeToRefs(userActionStore);

const initialFilters: Filters = {
  search: "",
  teams: [null],
  roles: [],
  statusKey: "all",
};

const filters = ref<Filters>({ ...initialFilters });
const isSecondaryFiltersVisible = ref(false);
const postPayload = computed(() => {
  const selectedStatusKey = filters.value.statusKey;
  return {
    DateFrom: "2024-01-02T00:00:00.000Z",
    DateTo: "2024-06-04T00:00:00.000Z",
    SearchFor: filters.value.search,
    Tags: filters.value.teams[0] === null ? [] : filters.value.teams,
    PersonStatusId:
      selectedStatusKey === "all" ? -1 : USER_STATUS[selectedStatusKey],
  };
});

const {
  data: usersListResponse,
  isFetching,
  error: usersListError,
} = useFetch(`/api/ReportRegisteredStudents2`, { refetch: true })
  .post(postPayload)
  .json<UsersResponse[]>();

const userList = computed(() => {
  if (!usersListResponse.value) return;
  return usersListResponse.value.map((user) => {
    return {
      id: user.PersonId,
      name: `${user.FirstName} ${user.LastName}`,
      fuid: user.ForeignUsername,
      email: user.Email,
      role: "Role",
      team: "Team",
      status: user.StatusId,
      lastActive: "Last Active",
    };
  });
});
const defaultSort = [{ desc: true, id: "name" }];

const tableDefinition: TableColumn<ColumnAccessor>[] = [
  {
    id: "name",
    headerLabel: t("common.name"),
    accessor: "name",
    smallScreenPosition: "center",
  },
  {
    id: "role",
    headerLabel: t("organisation.role"),
    accessor: "role",
    visibleFrom: false,
  },
  {
    id: "team",
    headerLabel: t("reports.team"),
    accessor: "team",
    visibleFrom: false,
  },
  {
    id: "status",
    headerLabel: t("common.status"),
    accessor: "status",
  },
  {
    id: "lastActive",
    headerLabel: t("common.last_active"),
    accessor: "lastActive",
    visibleFrom: false,
  },
  {
    id: "actions",
    headerLabel: t("common.actions"),
    smallScreenPosition: "last",
  },
];

const availableStatuses = computed<Record<UserStatusKey, number>>(() => {
  if (!userList.value) return;

  return userList.value.reduce(
    (statuses, user) => {
      const statusKey = userStatusValueToKey[user.status];
      if (statusKey in statuses) {
        statuses[statusKey] += 1;
      } else {
        statuses[statusKey] = 1;
      }
      return statuses;
    },
    {} as Record<UserStatusKey, number>,
  );
});
</script>
<template>
  <GToggleBar :label="t('common.toggle_bar_label')" opt="in" />
  <PageLayout :title="t('dashboardMenu.organisation_users')" heading="Users">
    <template #primaryAction>
      <ActionMenu v-if="!'not ready yet'">
        <template #trigger="{ setElementRef, isMenuOpen }">
          <GDropdownButtonTrigger
            singleSelectedOptionLabel="Bulk Actions"
            :isMenuOpen="isMenuOpen"
            :ref="setElementRef"
          />
        </template>
      </ActionMenu>
    </template>
    <GStateHandlerWrapper :isLoading="isFetching" :error="usersListError">
      <template v-if="userList">
        <FilterPanel
          v-model:search="filters.search"
          :isSecondaryFiltersVisible="isSecondaryFiltersVisible"
          @reset="filters = { ...initialFilters }"
          @toggleSecondaryFilters="
            isSecondaryFiltersVisible = !isSecondaryFiltersVisible
          "
        >
          <template #primaryFilters>
            <UserStatusFilter
              v-if="availableStatuses"
              :statuses="availableStatuses"
              v-model="filters.statusKey"
            />
          </template>
          <template #secondaryFilters v-if="!'not ready yet'">
            <TeamsSelect v-model="filters.teams" />
            <RolesSelect v-model="filters.roles" />
          </template>
        </FilterPanel>
        <GTable
          :data="userList"
          :columns="tableDefinition"
          :selectedList="selectedUsers"
          @selectedListChange="
            (list) => selectedUsers.splice(0, selectedUsers.length, ...list)
          "
          :defaultSort="defaultSort"
          :selectable="true"
        >
          <template v-slot:name="{ row }">
            <GNameCard :name="row.name" :fuid="row.fuid" :email="row.email" />
          </template>
          <template v-slot:status="{ value }">
            <StatusBadge :status="value as number" />
          </template>
          <template v-slot:actions="{ row }">
            <ActionMenu @open="activeRow = row" @close="activeRow = null">
              <template #trigger="{ setElementRef }">
                <GDropdownIconButtonTrigger
                  :icon="EllipsisVerticalIcon"
                  :ref="setElementRef"
                />
              </template>
            </ActionMenu>
          </template>
        </GTable>
      </template>
      <ActionBar :isOpen="!activeRow && selectedUsers.length > 0" />
      <ModalRevokeInvitation />
      <ModalAnonymise />
      <ModalBlock />
      <ModalExport />
    </GStateHandlerWrapper>
  </PageLayout>
</template>
