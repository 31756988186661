import { computed, watch, type ComputedRef, ref, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import type { TableColumn } from "@/patterns";
import { useLocale, type FilterDefinition } from "@/composables";
import { downloadAssessmentRS } from "@/utils/createRemoteScormPackage";
import {
  useAssessmentsStore,
  useExportStore,
  useToastStore,
  useUserStore,
} from "@/stores";
import { exportToCSV, exportToExcel, formatLtiData } from "@/utils/export";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { createAsmFetch } from "@/composables/useFetch";
import type {
  AssessmentMetadata,
  AssessmentTableDataRow,
  AssessmentTableResponse,
  ColumnAccessor,
} from "./types";

export const useAssessmentsTab = (asmLocale: Ref<string>) => {
  const { t } = useI18n();
  const { locale } = useLocale();
  const assessmentTableDefinition: TableColumn<ColumnAccessor>[] = [
    {
      id: "thumbnail",
      headerLabel: t("common.thumbnail"),
      accessor: "thumbnail",
      sortable: false,
      smallScreenPosition: "last",
    },
    {
      id: "title",
      headerLabel: t("common.name"),
      accessor: "title",
      smallScreenPosition: "center",
    },
    {
      id: "lti11Link",
      visibleFrom: false,
    },
    {
      id: "actions",
      headerLabel: t("common.actions"),
    },
  ];

  const useAsmFetch = createAsmFetch();
  const { isFetching, data, error, execute } = useAsmFetch(
    `/api/AssessmentLibrary`,
    {
      async beforeFetch({ options }) {
        options.headers = {
          ...options.headers,
          locale: asmLocale.value,
        };
        return {
          options,
        };
      },
    },
  ).json<AssessmentTableResponse[]>();

  const assessmentData = computed(
    () =>
      data.value
        ?.filter(
          (assessment) =>
            assessment.publicationStatus !== "Hidden" &&
            assessment.accessRight === "startable",
        )
        .map((assessment) => ({
          id: assessment.assessmentId,
          title: assessment.title,
          thumbnail: assessment.tile?.backgroundImage?.uri,
          backgroundShape: assessment.tile?.backgroundShape,
          backgroundColor: assessment.tile?.backgroundColor,
          backgroundShapePosition: assessment.tile?.backgroundShapePosition,
          lti11Link: `${
            import.meta.env.VITE_GH_TOOL_DOMAIN
          }/int-lti/launchAsm.aspx?asmid=${assessment.assessmentId}&locale=${
            asmLocale.value
          }`,
          assessmentLink: `https://my.goodhabitz.com/${locale.value}/assessments/${assessment.assessmentId}`,
        })) ?? [],
  );

  const {
    data: asmMetaData,
    isFetching: isFetchingAsmMetaData,
    error: errorAsmMetaData,
  } = useAsmFetch(`/api/AssessmentMetadata`).json<AssessmentMetadata[]>();

  const filterDefinition: ComputedRef<
    (FilterDefinition<ColumnAccessor> | FilterDefinition<string>)[]
  > = computed(() => {
    return [
      {
        id: "title",
        type: "search",
        startingValue: "",
        label: t("common.search"),
      },
    ];
  });

  watch(asmLocale, () => {
    execute();
  });

  const selectedRows = ref<AssessmentTableDataRow[]>([]);

  const exportableData = computed(() => {
    let columns: { id: string; header: string }[] = [];
    const dataSource: AssessmentMetadata[] = [];
    if (asmMetaData.value && selectedRows.value.length > 0) {
      columns = Object.keys(asmMetaData.value[0]).map((key) => ({
        id: key,
        header: key,
      }));
      selectedRows.value.forEach((row) => {
        const rowMeta = asmMetaData.value?.find(
          (asm) =>
            asm.assessmentId === row.id && asm.locale === asmLocale.value,
        );
        if (rowMeta) {
          // DASH 541 temporary fixes slashes at the start of urls
          rowMeta.imageIcon
            ? (rowMeta.imageIcon = rowMeta.imageIcon.replace(/^\/{2}/, ""))
            : null;
          rowMeta.url
            ? (rowMeta.url = rowMeta.url.replace(/^\/{2}/, ""))
            : null;
          rowMeta.imageUrlLandscape
            ? (rowMeta.imageUrlLandscape = rowMeta.imageUrlLandscape.replace(
                /^\/{2}/,
                "",
              ))
            : null;
          rowMeta.imageUrlPortrait
            ? (rowMeta.imageUrlPortrait = rowMeta.imageUrlPortrait.replace(
                /^\/{2}/,
                "",
              ))
            : null;
          rowMeta.imageUrlSquare = rowMeta.imageUrlSquare.replace(/^\/{2}/, "");

          // DASH 545 temporary fix not all asm have locale queryparam
          rowMeta.ltiLaunchUrl = checkAndAddLocale(
            rowMeta.ltiLaunchUrl,
            rowMeta.locale,
          );

          dataSource.push(rowMeta);
        }
      });
    }
    return { columns, dataSource };
  });

  function checkAndAddLocale(url: string, locale: string): string {
    const launchUrl = new URL(url);
    if (!launchUrl.searchParams.has("locale")) {
      launchUrl.searchParams.append("locale", locale);
    }

    return launchUrl.toString();
  }

  const exportStore = useExportStore();
  const { shouldIncludeMetadata, selectedFileFormat } =
    storeToRefs(exportStore);

  const exportToScorm = async (data: AssessmentTableDataRow[]) => {
    const userStore = useUserStore();
    const asmStore = useAssessmentsStore();
    const toastStore = useToastStore();
    if (!asmStore.isAssessmentsUser) {
      toastStore.addToast({
        header: t("error.could_not_download"),
        body: t("error.could_not_download_rights"),
      });
      return;
    }
    const companyId = userStore.user?.CompanyId ?? 0;

    const promises = data.map(async (row) => {
      try {
        await downloadAssessmentRS({
          assessmentId: row.id,
          assessmentName: row.title,
          appsDomain: import.meta.env.VITE_GH_TOOL_DOMAIN,
          companyId: companyId,
          locale: asmLocale.value,
        });
      } catch (error) {
        console.log(error);
      }
    });

    await Promise.all(promises);

    if (shouldIncludeMetadata.value) {
      const metadata = formatLtiData(exportableData.value);
      if (selectedFileFormat.value === "csv") exportToCSV(metadata, "metadata");
      else exportToExcel(metadata, "metadata");
    }
  };

  const trackExportAssessments = (
    selectedActivities: AssessmentTableDataRow[],
  ) => {
    trackMixPanelEvent("assessmentsExport", {
      count: selectedActivities.length,
      activities: selectedActivities.map((activity) => ({
        id: activity.id,
        name: activity.title,
      })),
      context: "Connect Page",
    });
  };

  return {
    isFetching,
    error,
    errorAsmMetaData,
    isFetchingAsmMetaData,
    assessmentTableDefinition,
    assessmentData,
    selectedRows,
    filterDefinition,
    exportToScorm,
    exportableData,
    trackExportAssessments,
  };
};
