<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { saveAs } from "file-saver";

import { GRange, GRadioGroup, GTooltip } from "@/components";
import { useCoursesTab, type CourseTableDataRow } from "./useCoursesTab";
import {
  useUserStore,
  useCourseStore,
  useExportStore,
  useFlagStore,
} from "@/stores";
import {
  downloadCourseRS,
  downloadMultiLanguageRS,
} from "@/utils/createRemoteScormPackage";
import { exportToCSV, exportToExcel, formatLtiData } from "@/utils/export";
import ExportModal from "../components/ExportModal.vue";
import type { FormatLtiDataOptions } from "@/types/export";
import type { CourseDetails } from "@/types/course";
import { useLocale } from "@/composables";

const { t } = useI18n();

enum ContentMode {
  BOTH = 1,
  LESSONS,
  TEST,
}

enum LessonStatusSuccess {
  Completed = "completed",
  Passed = "passed",
}

enum LessonStatusFail {
  Incomplete = "incomplete",
  Failed = "failed",
}

const courseStore = useCourseStore();

const { locale } = useLocale();

type ExportModalProps = {
  isOpen: boolean;
  selectedRows: CourseTableDataRow[];
};

const props = defineProps<ExportModalProps>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "exportSucceeded"): void;
}>();

const exportStore = useExportStore();
const {
  selectedExportType,
  shouldIncludeMetadata,
  shouldExportMultiLanguage,
  selectedFileFormat,
} = storeToRefs(exportStore);

const { courses } = storeToRefs(courseStore);
const { trackExportCourses } = useCoursesTab();
const userStore = useUserStore();

const contentModes = [
  { label: t("contentSelection.lessons_test"), value: ContentMode.BOTH },
  { label: t("contentSelection.lessons"), value: ContentMode.LESSONS },
  { label: t("contentSelection.test"), value: ContentMode.TEST },
];

const selectedContentModesLTI = ref<ContentMode[]>([]);
const selectedContentModeScorm = ref<ContentMode>(ContentMode.BOTH);

const lessonStatusSuccessOptions = [
  { label: "completed", value: LessonStatusSuccess.Completed },
  { label: "passed", value: LessonStatusSuccess.Passed },
];
const lessonStatusFailOptions = [
  { label: "incomplete", value: LessonStatusFail.Incomplete },
  { label: "failed", value: LessonStatusFail.Failed },
];

const BASE_THRESHOLD = 70;

const lessonStatusSuccess = ref(LessonStatusSuccess.Completed);
const lessonStatusFail = ref(LessonStatusFail.Incomplete);
const threshold = ref(BASE_THRESHOLD);

const dataSource = computed(() => {
  const result: CourseDetails[] = [];

  props.selectedRows.forEach((row) => {
    const courseDetails = courseStore.coursesDetails.find(
      (courseDetails) => courseDetails.CourseId === row.id,
    );
    if (courseDetails) {
      result.push(courseDetails);
    }
  });
  return result;
});

const formattedDataColumns = computed(() => {
  const columns: FormatLtiDataOptions<CourseDetails>["columns"] = [];

  const metadataColumns: FormatLtiDataOptions<CourseDetails>["columns"] = [
    {
      id: "CourseId",
      header: t("contentSelection.course_id"),
    },
    {
      id: "CourseCode",
      header: t("contentSelection.course_code"),
    },
    {
      id: "CourseName",
      header: t("contentSelection.course_name"),
    },
    {
      id: "CourseTitle",
      header: t("contentSelection.course_title"),
    },
    {
      id: "Url",
      header: t("contentSelection.course_link"),
    },
    {
      id: "ImageUrlPortrait",
      header: t("contentSelection.image_portrait"),
    },
    {
      id: "ImageUrlSquare",
      header: t("contentSelection.image_square"),
    },
    {
      id: "ImageUrlLandscape",
      header: t("contentSelection.image_landscape"),
    },
    {
      id: "Description",
      header: t("contentSelection.course_description"),
    },
    {
      id: "Tags",
      header: t("contentSelection.tags"),
    },
    {
      id: "Duration",
      header: t("contentSelection.duration"),
    },
    {
      id: "PublishDate",
      header: t("contentSelection.date_of_publication"),
    },
    {
      id: "PublicationStatus",
      header: t("contentSelection.publication_status"),
    },
    {
      id: "Language",
      header: t("contentSelection.language"),
    },
    {
      id: "LanguageCode",
      header: t("contentSelection.language_code"),
    },
    {
      id: "CountryCode",
      header: t("contentSelection.country_code"),
    },
    {
      id: "CategoryId",
      header: t("contentSelection.category_id"),
    },
    {
      id: "Category",
      header: t("contentSelection.category"),
    },
    {
      id: "CoursecontainerId",
      header: t("contentSelection.container_id"),
    },
    {
      id: "Coursecontainer",
      header: t("contentSelection.course_container"),
    },
    {
      id: "TestAvailable",
      header: t("contentSelection.test_available"),
    },
    {
      id: "MobileFriendly",
      header: t("contentSelection.mobile_friendly"),
    },
    {
      id: "CourseAreaId",
      header: t("contentSelection.course_area_id"),
    },
    {
      id: "ImageIcon",
      header: t("contentSelection.image_icon"),
    },
    {
      id: "ImageSquareNoText",
      header: t("contentSelection.image_square_no_text"),
    },
    {
      id: "TrainingType",
      header: t("contentSelection.training_type"),
    },
  ];

  const ltiFields: Record<
    "lti" | "lti13",
    Record<ContentMode, FormatLtiDataOptions<CourseDetails>["columns"][0]>
  > = {
    lti: {
      [ContentMode.BOTH]: {
        id: "LtiLaunchUrlComplete",
        header: "LtiLaunchUrlComplete",
      },
      [ContentMode.LESSONS]: {
        id: "LtiLaunchUrlContentOnly",
        header: "LtiLaunchUrlContentOnly",
      },
      [ContentMode.TEST]: {
        id: "LtiLaunchUrlTestOnly",
        header: "LtiLaunchUrlTestOnly",
      },
    },
    lti13: {
      [ContentMode.BOTH]: {
        id: "Lti13LaunchUrlComplete",
        header: "Lti13LaunchUrlComplete",
      },
      [ContentMode.LESSONS]: {
        id: "Lti13LaunchUrlContentOnly",
        header: "Lti13LaunchUrlContentOnly",
      },
      [ContentMode.TEST]: {
        id: "Lti13LaunchUrlTestOnly",
        header: "Lti13LaunchUrlTestOnly",
      },
    },
  };

  const cellsCountAfterLtiFields = 21;
  const indexToInsertLtiFields =
    metadataColumns.length - cellsCountAfterLtiFields;
  if (selectedExportType.value === "lti" && !shouldIncludeMetadata.value) {
    if (selectedContentModesLTI.value.includes(ContentMode.BOTH)) {
      columns.splice(
        indexToInsertLtiFields,
        0,
        ...[ltiFields.lti[ContentMode.BOTH], ltiFields.lti13[ContentMode.BOTH]],
      );
    }

    if (selectedContentModesLTI.value.includes(ContentMode.LESSONS)) {
      columns.splice(
        indexToInsertLtiFields,
        0,
        ...[
          ltiFields.lti[ContentMode.LESSONS],
          ltiFields.lti13[ContentMode.LESSONS],
        ],
      );
    }

    if (selectedContentModesLTI.value.includes(ContentMode.TEST)) {
      columns.splice(
        indexToInsertLtiFields,
        0,
        ...[ltiFields.lti[ContentMode.TEST], ltiFields.lti13[ContentMode.TEST]],
      );
    }
  }

  if (shouldIncludeMetadata.value) {
    columns.push(...metadataColumns);
    columns.splice(
      indexToInsertLtiFields,
      0,
      ...Object.values(ltiFields.lti13),
    );
    columns.splice(indexToInsertLtiFields, 0, ...Object.values(ltiFields.lti));
  }

  return columns;
});

const remoteScormThreshold = computed(() => {
  return selectedContentModeScorm.value === ContentMode.TEST
    ? BASE_THRESHOLD
    : threshold.value;
});

const remoteScormLessonStatusSuccess = computed(() => {
  switch (selectedContentModeScorm.value) {
    case ContentMode.TEST:
      return LessonStatusSuccess.Passed;
    case ContentMode.LESSONS:
      return LessonStatusSuccess.Completed;
    default:
      return lessonStatusSuccess.value;
  }
});

const remoteScormLessonStatusFail = computed(() => {
  switch (selectedContentModeScorm.value) {
    case ContentMode.TEST:
      return LessonStatusFail.Failed;
    case ContentMode.LESSONS:
      return LessonStatusFail.Incomplete;
    default:
      return lessonStatusFail.value;
  }
});

const formatMultiLanguageRSPayload = () => {
  const courseContainerIds = props.selectedRows.map((row) => row.containerId);
  const uniqueCourseContainerIds = [...new Set(courseContainerIds)];
  return uniqueCourseContainerIds.map((id) => {
    const item = {
      courseContainerId: id,
      appsDomain: import.meta.env.VITE_GH_TOOL_DOMAIN,
      companyId: userStore.user?.CompanyId ?? 0,
      mode: selectedContentModeScorm.value,
      mixedTrainingResultComplete: remoteScormLessonStatusSuccess.value,
      mixedTrainingResultIncomplete: remoteScormLessonStatusFail.value,
      threshold: remoteScormThreshold.value,
      locale: locale.value,
      allCourses: courses.value,
    };
    return item;
  });
};

const formatRSPayload = () => {
  const modeToSuffixMap = {
    [ContentMode.BOTH]: "all",
    [ContentMode.LESSONS]: "lessonsonly",
    [ContentMode.TEST]: "testonly",
  } as const;

  return props.selectedRows.map((row) => {
    const fileName = `${row.courseName}-${
      modeToSuffixMap[selectedContentModeScorm.value]
    }`;
    const item = {
      courseCode: row.courseCode.toString(),
      courseName: row.title,
      appsDomain: import.meta.env.VITE_GH_TOOL_DOMAIN,
      companyId: userStore.user?.CompanyId ?? 0,
      mode: selectedContentModeScorm.value,
      threshold: remoteScormThreshold.value,
      mixedTrainingResultComplete: remoteScormLessonStatusSuccess.value,
      mixedTrainingResultIncomplete: remoteScormLessonStatusFail.value,
      fileName,
    };
    return item;
  });
};

const downloadSCORMPackage = async () => {
  if (!props.selectedRows.length) return;
  const batchSize = 10;
  const delay = 1000;
  try {
    const promises = shouldExportMultiLanguage.value
      ? formatMultiLanguageRSPayload().map(downloadMultiLanguageRS)
      : formatRSPayload().map(downloadCourseRS);

    /**
     * Process promises in batches with a delay to avoid server overload
     * and allow browsers to handle files to be downloaded properly
     */
    for (let i = 0; i < promises.length; i += batchSize) {
      const currentBatch = promises.slice(i, i + batchSize);
      await Promise.all(currentBatch.map((command) => command.fetch.execute()));
      currentBatch.map((promise) =>
        saveAs(promise.fetch.data.value, `${promise.fileName}.zip`),
      );
      await new Promise((resolve) => setTimeout(resolve, delay));
    }

    if (shouldIncludeMetadata.value) {
      const metadata = formatLtiData({
        dataSource: dataSource.value as any,
        columns: formattedDataColumns.value as any,
      });
      if (selectedFileFormat.value === "csv") exportToCSV(metadata, "metadata");
      else exportToExcel(metadata, "metadata");
    }

    trackExportCourses(props.selectedRows, shouldExportMultiLanguage.value);
    emit("exportSucceeded");
  } catch (error) {
    console.log(error);
  }
};
const flagStore = useFlagStore();
const { multiLangSCOExportFlag } = storeToRefs(flagStore);
const showMultiLanguageScoExport = computed(() => {
  return selectedExportType.value == "scorm" && multiLangSCOExportFlag.value;
});

const handleLTIExportSuccess = () => {
  trackExportCourses(props.selectedRows, shouldExportMultiLanguage.value);
  emit("exportSucceeded");
};
</script>
<template>
  <ExportModal
    :isOpen="isOpen"
    ltiFileName="gh-course-export"
    :data="{
      dataSource: dataSource as any,
      columns: formattedDataColumns as any,
    }"
    :exportTypes="['lti', 'scorm', 'metadata']"
    @close="$emit('close')"
    @exportToScorm="downloadSCORMPackage()"
    @exportToLtiSuccess="handleLTIExportSuccess"
  >
    <GRadioGroup
      v-if="selectedExportType === 'scorm'"
      class="mb-6"
      name="selectedContentFormats"
      v-model="selectedContentModeScorm"
      :options="contentModes"
    >
      <template #label>{{
        t("contentSelection.scorm_package_options")
      }}</template>
    </GRadioGroup>
    <div
      v-if="selectedExportType === 'lti'"
      class="mb-2 flex flex-col gap-2 py-4"
    >
      <label class="label-text mb-2 inline-block font-medium text-black">{{
        t("contentSelection.lti_url_options")
      }}</label>
      <label
        v-for="mode in contentModes"
        :key="mode.value"
        class="flex items-center gap-2"
      >
        <input
          type="checkbox"
          :value="mode.value"
          v-model="selectedContentModesLTI"
          class="checkbox checkbox-primary checkbox-sm"
        />{{ mode.label }}</label
      >
    </div>
    <div v-if="selectedExportType === 'scorm'" class="flex flex-col gap-4">
      <GRange
        :disabled="selectedContentModeScorm !== ContentMode.BOTH"
        :modelValue="remoteScormThreshold"
        @update:modelValue="threshold = $event"
        :step="5"
        :label="t('contentSelection.threshold_pass')"
      />
      <GRadioGroup
        name="lessonStatusSuccess"
        :modelValue="remoteScormLessonStatusSuccess"
        @update:modelValue="lessonStatusSuccess = $event"
        :options="lessonStatusSuccessOptions"
        :isDisabled="selectedContentModeScorm !== ContentMode.BOTH"
      >
        <template #label>
          <span> {{ t("contentSelection.status_pass") }}</span>
          <GTooltip class="bg-gray-900 p-2 text-white">
            <template #content>
              {{ t("contentSelection.status_pass_tooltip") }}
            </template>
          </GTooltip>
        </template>
      </GRadioGroup>
      <GRadioGroup
        name="lessonStatusFail"
        :modelValue="remoteScormLessonStatusFail"
        @update:modelValue="lessonStatusFail = $event"
        :options="lessonStatusFailOptions"
        :isDisabled="selectedContentModeScorm !== ContentMode.BOTH"
      >
        <template #label>
          <span> {{ t("contentSelection.status_pass_not") }}</span>
          <GTooltip class="bg-gray-900 p-2 text-white">
            <template #content>
              {{ t("contentSelection.status_pass_not_tooltip") }}
            </template>
          </GTooltip>
        </template>
      </GRadioGroup>
    </div>
    <span
      v-if="showMultiLanguageScoExport"
      class="flex items-center gap-2 border-t py-4"
      ><input
        type="checkbox"
        v-model="shouldExportMultiLanguage"
        class="checkbox checkbox-primary checkbox-sm"
      />
      {{ t("contentSelection.export_multi_lang") }}</span
    >
    <span
      v-if="selectedExportType !== 'metadata'"
      class="flex items-center gap-2 border-t py-4"
      ><input
        type="checkbox"
        v-model="shouldIncludeMetadata"
        class="checkbox checkbox-primary checkbox-sm"
      />
      {{ t("contentSelection.add_content_meta") }}</span
    >
  </ExportModal>
</template>
