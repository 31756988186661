<script setup lang="ts">
import type { ExportTableData } from "@/types/export";

const props = defineProps<{ type: string }>();
const storedData = sessionStorage.getItem(props.type);
const tableData: ExportTableData | null = storedData
  ? JSON.parse(storedData)
  : null;
</script>
<template>
  <div class="m-4 ml-24 overflow-x-auto">
    <table class="table-compact table bg-white">
      <thead>
        <tr>
          <th
            class="first-of-type:!static"
            v-for="(header, i) in tableData?.headers"
            :key="i"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in tableData?.rows" :key="i">
          <td v-for="(cell, i) in row" :key="i">
            {{ cell }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
