import { useWebSessionStore } from "@/stores";
import { useWebCatalogFetch } from "@/composables/useFetch";
import type Course from "@/types/course";
import { saveAs } from "file-saver";

type DownloadAssessmentRSArgs = {
  assessmentId: string;
  assessmentName: string;
  appsDomain: string;
  companyId: number;
  locale: string;
};

export async function downloadAssessmentRS({
  assessmentId,
  assessmentName,
  appsDomain,
  companyId,
  locale,
}: DownloadAssessmentRSArgs) {
  const { data } = await useWebCatalogFetch("/api/export-scorm/assessment")
    .post({
      assessmentId,
      assessmentName,
      appsDomain,
      companyId,
      locale,
    })
    .blob();

  if (data.value) {
    saveAs(
      data.value,
      `${removeSpecialCharactersAndAccents(assessmentName)}.zip`,
    );
  }
}

type MultiLangCourseData = {
  CourseId: number;
  Lang: string;
  Title: string;
  CourseGuid: string;
  CourseCode: string;
  CourseContainerId: number;
  CourseName: string;
};

function getCourseContainerCourses(
  courseContainerId: number,
  locale: string,
  allCourses: Course[],
) {
  const courses: MultiLangCourseData[] = [];
  let containerName = "multi-lang-RS";

  allCourses.forEach((course: Course) => {
    if (
      course.CourseContainerId === courseContainerId &&
      course.PublicationStatus === 1
    ) {
      const item = {
        CourseId: course.CourseId,
        Lang: course.Lang,
        Title: course.properties.title,
        CourseGuid: course.CourseGuid,
        CourseCode: course.CourseCode,
        CourseContainerId: course.CourseContainerId,
        CourseName: course.CourseName,
      };
      courses.push(item);
    }
  });

  if (courses.length) {
    containerName = getContainerName(courses, locale);
  } else {
    console.error("invalid course data for: ", courseContainerId);
  }

  return { courses: courses, containerName: containerName };
}
export const getContainerName = (
  courses: MultiLangCourseData[],
  locale: string,
): string => {
  const currentLang = locale.split("-")[0].toUpperCase();

  let containerName = (
    courses.find((course) => course.Lang === currentLang) ||
    courses.find((course) => course.Lang === "EN") ||
    courses[0]
  ).CourseName;

  // replace the language code in the found container name with multi-lang
  containerName = containerName.replace(
    /(\d{4})-(\w{2}-\w{2})-(.*)/,
    `$1-multi-lang-$3`,
  );

  return containerName;
};

type DownloadMultiLanguageRSArgs = {
  courseContainerId: number;
  appsDomain: string;
  mode: number;
  threshold: number;
  mixedTrainingResultComplete: string;
  mixedTrainingResultIncomplete: string;
  companyId: number;
  locale: string;
  allCourses: Course[];
};

export function downloadMultiLanguageRS({
  courseContainerId,
  appsDomain,
  mode,
  threshold,
  mixedTrainingResultComplete,
  mixedTrainingResultIncomplete,
  companyId,
  locale,
  allCourses,
}: DownloadMultiLanguageRSArgs) {
  const courseContainerCourses = getCourseContainerCourses(
    courseContainerId,
    locale,
    allCourses,
  );

  if (!courseContainerCourses.courses.length) {
    throw new Error(
      "no courses found for courseContainerId: " + courseContainerId,
    );
  }

  const allLanguages = getAvailableLanguages(courseContainerCourses.courses);
  const fetch = useWebCatalogFetch("/api/export-scorm/multiLangSco", {
    immediate: false,
  })
    .post({
      allLanguages,
      name: courseContainerCourses.containerName,
      courses: courseContainerCourses.courses,
      appsDomain,
      mode,
      threshold,
      mixedTrainingResultComplete,
      mixedTrainingResultIncomplete,
      companyId,
    })
    .blob();

  return {
    fetch,
    fileName: removeSpecialCharactersAndAccents(
      courseContainerCourses.containerName,
    ),
  };
}

export type DownloadCourseRS = {
  courseCode: string;
  courseName: string;
  appsDomain: string;
  mode: number;
  threshold: number;
  mixedTrainingResultComplete: string;
  mixedTrainingResultIncomplete: string;
  companyId: number;
  fileName: string;
};

export function downloadCourseRS({
  courseCode,
  courseName,
  appsDomain,
  mode,
  threshold,
  mixedTrainingResultComplete,
  mixedTrainingResultIncomplete,
  companyId,
  fileName,
}: DownloadCourseRS) {
  const fetch = useWebCatalogFetch("/api/export-scorm/course", {
    immediate: false,
  })
    .post({
      courseCode,
      courseName,
      appsDomain,
      mode,
      threshold,
      mixedTrainingResultComplete,
      mixedTrainingResultIncomplete,
      companyId,
    })
    .blob();

  return {
    fetch,
    fileName,
  };
}

const getAvailableLanguages = (courses: MultiLangCourseData[]) => {
  const webSessionStore = useWebSessionStore();
  const lang: { lang: string; label: string }[] = [];
  courses.forEach((course) => {
    let langName = course.Lang.toUpperCase();
    webSessionStore.webSession?.LanguageList.forEach((langObj) => {
      if (course.Lang.toUpperCase() === langObj.LanguageCode.toUpperCase()) {
        langName = langObj.LanguageName;
      }
    });
    lang.push({
      lang: course.Lang.toUpperCase(),
      label: langName, //TODO: get actual language labels
    });
  });
  return lang;
};

export const removeSpecialCharactersAndAccents = (s: string): string => {
  const normalized = s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const cleaned = normalized
    .replace(/[^a-zA-Z0-9 \u4e00-\u9fa5\u0400-\u04FF-]/g, "")
    .replace(/\s+/g, "-");

  return cleaned;
};

export type DownloadLessonRS = {
  courseCode: string;
  courseName: string;
  appsDomain: string;
  companyId: number;
};

export async function downloadLessonRS({
  courseCode,
  courseName,
  appsDomain,
  companyId,
}: DownloadLessonRS) {
  const { data } = await useWebCatalogFetch("/api/export-scorm/lesson")
    .post({
      courseCode,
      courseName,
      appsDomain,
      companyId,
    })
    .blob();

  if (data.value) {
    saveAs(data.value, `${removeSpecialCharactersAndAccents(courseName)}.zip`);
  }
}
