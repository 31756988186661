<script setup lang="ts">
import { computed } from "vue";
import type { RouteLocationRaw } from "vue-router";

const props = defineProps<{ href?: RouteLocationRaw }>();

const isExternalLink = computed(() => {
  const href = props.href?.toString();
  return (
    href &&
    (href.startsWith("http") ||
      !/\/onboarding\/|\/admin-dashboard\//.test(href))
  );
});
</script>
<template>
  <component
    :is="href ? (isExternalLink ? 'a' : 'RouterLink') : 'button'"
    :href="isExternalLink ? href : undefined"
    :to="isExternalLink ? undefined : href"
  >
    <slot></slot>
  </component>
</template>
