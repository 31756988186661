<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { StatusFilterKey } from "./types";

type StatusFilterPresentation = {
  label: string;
  countClass: string;
};

const props = defineProps<{
  status: StatusFilterKey;
  count: number | null;
  isActive: boolean;
}>();

const emit = defineEmits<{
  (e: "select", status: StatusFilterKey): void;
}>();

const { t } = useI18n();

const availableStatuses: Record<
  Exclude<StatusFilterKey, "created">,
  StatusFilterPresentation
> = {
  all: {
    label: t("user_status.all"),
    countClass: "bg-info-light text-info",
  },
  active: {
    label: t("user_status.active"),
    countClass: "bg-success-light text-success",
  },
  invited: {
    label: t("user_status.invited"),
    countClass: "bg-warning-light text-warning",
  },
  blocked: {
    label: t("user_status.blocked"),
    countClass: "bg-error-light text-error",
  },
};
</script>
<template>
  <div
    @click="emit('select', props.status)"
    class="flex cursor-pointer items-center gap-2 rounded-md border border-grey-10 px-4 py-[0.39rem] hover:border-primary"
    :class="{ 'border-primary': isActive }"
  >
    <span
      v-show="props.count !== null"
      class="min-h-7 min-w-4 rounded p-1 text-sm"
      :class="[availableStatuses[props.status].countClass]"
      >{{ props.count ?? " " }}</span
    >
    <span class="leading-8 text-grey-80" :class="{ 'text-primary': isActive }">
      {{ availableStatuses[props.status].label }}
    </span>
  </div>
</template>
