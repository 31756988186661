import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import type { Lesson } from "@/types/lesson";
import type { TableColumn } from "@/patterns";
import { useLocale } from "@/composables";
import { useAssessmentsStore, useUserStore } from "@/stores";
import { useCourseBffFetch } from "@/composables/useFetch";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { downloadLessonRS } from "@/utils/createRemoteScormPackage";

type ColumnAccessor =
  | "id"
  | "title"
  | "imageUrl"
  | "publicationDate"
  | "language";

export type LessonTableDataRow = {
  id: number;
  ghccId: string;
  title: string;
  imageUrl: string;
  publicationDate: Date;
};

export type LessonMetadata = {
  id: number;
  ghccId: string;
  title: string;
  subtitle: string;
  description: string;
  imageUrl: string;
  language: string;
  ltiLaunchUrl: string;
  lessonUrl: string;
  publicationDate: Date;
};

export const useLessonsTab = (filterLanguage: string) => {
  const { t } = useI18n();
  const { locale } = useLocale();

  const asmStore = useAssessmentsStore();
  const { isAssessmentsFullUser } = storeToRefs(asmStore);

  const {
    isFetching,
    data,
    error: lessonError,
  } = useCourseBffFetch("/api/lessons/list").json<Lesson[]>();

  const lessonTableDefinition: TableColumn<ColumnAccessor>[] = [
    {
      id: "imageUrl",
      headerLabel: t("common.thumbnail"),
      accessor: "imageUrl",
      sortable: false,
      smallScreenPosition: "last",
    },
    {
      id: "title",
      headerLabel: t("common.name"),
      accessor: "title",
      smallScreenPosition: "center",
    },
    {
      id: "publicationDate",
      headerLabel: t("common.publication_date"),
      accessor: "publicationDate",
    },
    {
      id: "language",
      accessor: "language",
      visibleFrom: false,
    },
    {
      id: "actions",
      headerLabel: t("common.actions"),
    },
  ];

  const lessonData = computed(() =>
    data.value
      ?.filter((lesson) => lesson.language === filterLanguage || locale.value)
      .filter((lesson) => isAssessmentsFullUser.value || !lesson.hasAssessment)
      .map((lesson) => ({
        id: lesson.id,
        ghccId: lesson.ghccId,
        title: lesson.title,
        subtitle: lesson.subtitle,
        description: lesson.description,
        imageUrl: lesson.imageUrl,
        language: lesson.language,
        ltiLaunchUrl: `${import.meta.env.VITE_GH_TOOL_DOMAIN}/int-lti/${
          lesson.ghccId
        }`,
        lessonUrl: `https://my.goodhabitz.com/${lesson.language}/lessons/${lesson.id}`,
        publicationDate: new Date(lesson.publicationDate),
      })),
  );

  const selectedRows = ref<LessonTableDataRow[]>([]);

  const exportableData = computed(() => {
    let columns: { id: string; header: string }[] = [];
    const dataSource: LessonMetadata[] = [];

    if (selectedRows.value.length > 0) {
      columns = Object.keys(selectedRows.value[0]).map((key) => ({
        id: key,
        header: key,
      }));

      selectedRows.value.forEach((row) => {
        const rowMeta = data.value?.find((lesson) => lesson.id === row.id);
        if (rowMeta) {
          dataSource.push({
            id: rowMeta.id,
            ghccId: rowMeta.ghccId,
            title: rowMeta.title,
            subtitle: rowMeta.subtitle,
            description: rowMeta.description,
            imageUrl: rowMeta.imageUrl,
            language: rowMeta.language,
            ltiLaunchUrl: `${import.meta.env.VITE_GH_TOOL_DOMAIN}/int-lti/${
              rowMeta.ghccId
            }`,
            lessonUrl: `https://my.goodhabitz.com/${rowMeta.language}/lessons/${rowMeta.id}`,
            publicationDate: new Date(rowMeta.publicationDate),
          });
        }
      });
    }
    return { columns, dataSource };
  });

  const exportToScorm = (data: LessonTableDataRow[]) => {
    const userStore = useUserStore();
    const companyId = userStore.user?.CompanyId ?? 0;

    data.forEach((row) =>
      downloadLessonRS({
        courseCode: row.ghccId,
        courseName: row.title,
        appsDomain: import.meta.env.VITE_GH_TOOL_DOMAIN,
        companyId: companyId,
      }),
    );
  };

  const trackExportLessons = (selectedActivities: LessonTableDataRow[]) => {
    trackMixPanelEvent("lessonsExport", {
      count: selectedActivities.length,
      activities: selectedActivities.map((activity) => ({
        id: activity.id,
        name: activity.title,
      })),
      context: "Connect Page",
    });
  };

  return {
    lessonTableDefinition,
    isFetching,
    lessonData,
    lessonError,
    selectedRows,
    exportableData,
    exportToScorm,
    trackExportLessons,
  };
};
