import { datadogRum } from "@datadog/browser-rum";

export const initializeRUM = () => {
  if (!import.meta.env.PROD) {
    return;
  }

  return datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: "admin-dashboard",
    env: "production",
    version: import.meta.env.VITE_BUILD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
};
