import { useI18n } from "vue-i18n";
import { useFetch } from "@/composables";
import { useToastStore } from "@/stores";
import { downloadFile } from "@/utils/downloadFile";

export const useDownloadScorm = () => {
  const defaultPayload = {
    CompletedName: "",
    ExportType: -1,
    IncompleteName: "",
    Treshold: -1,
  };

  const toastStore = useToastStore();
  const { t } = useI18n();
  const downloadPackages = async (
    coursesData: { code: string; title: string }[],
    contentType: string
  ) =>
    await Promise.all(
      coursesData.map(async (courseData) => {
        const { data: scoBlob, statusCode } = await useFetch(
          "/api/CourseDownload"
        )
          .post({ ...defaultPayload, CourseCode: courseData.code })
          .blob();
        if (statusCode.value === 409)
          toastStore.addToast({
            header: t("error.could_not_download"),
            body: t("error.could_not_download_rights"),
          });
        else if (statusCode.value !== 200)
          toastStore.addToast({
            header: t("error.could_not_download"),
            body: t("error.reload_or_support"),
          });
        else if (scoBlob.value)
          downloadFile(
            scoBlob.value,
            `${contentType} - ${courseData.title}.zip`
          );
      })
    );

  return {
    downloadPackages,
  };
};
