import { defineStore } from "pinia";
import { useFetch } from "@/composables";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

type Team = {
  ActiveUserCount: number;
  CompanyId: number;
  Id: number;
  InvitedUserCount: number;
  Links: unknown | null;
  Name: string;
  PersonId: number;
  Private: boolean;
};

export const useTeamsStore = defineStore("teams", () => {
  const {
    data,
    isFetching: isFetchingTeams,
    error: teamsError,
  } = useFetch(`/api/Tag`).json<Team[]>();
  const { t } = useI18n();

  const teamsList = computed(() => {
    return data.value?.sort((a, b) => a.Name.localeCompare(b.Name));
  });

  const teamsListOptions = computed(() => {
    const options =
      teamsList.value?.map((team) => ({
        label: team.Name,
        value: team.Id,
      })) || [];
    return [{ label: t("common.no_team"), value: -1 }, ...options];
  });

  const { data: myTeams } = useFetch("api/tagperson/mine", {
    initialData: [],
  }).json<Array<Record<number, string>>>();

  const myTeamsListOptions = computed(() => {
    if (!myTeams.value) return [];
    return myTeams.value?.map((team) => {
      const [key, value] = Object.entries(team)[0];
      return {
        label: value,
        value: parseInt(key),
      };
    });
  });

  return {
    teamsList,
    teamsListOptions,
    myTeamsListOptions,
    isFetchingTeams,
    teamsError,
  };
});
