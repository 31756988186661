<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import {
  GStateHandlerWrapper,
  GButton,
  GTable,
  type TableColumn,
  GCard,
} from "@/components";
import { useFetch, useLocale } from "@/composables";
import type { LanguageCode } from "@/utils/i18n";
import { useToastStore } from "@/stores";
import { parseStringToJSON } from "@/utils/misc";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";
import { downloadFile } from "@/utils/downloadFile";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";

const { t } = useI18n();
const { locale } = useLocale();

type Resource = {
  Id: number;
  Name: string;
  ResourceCategoryId: number;
  ResourceCategoryName: string;
  DisplayOrder: number;
  Language: LanguageCode;
  FileName: string;
  ContentType: string;
};

type ResourceTableRow = {
  title: string;
  id: number;
  subRows: ResourceTableSubRow[];
};

type ResourceTableSubRow = {
  id: number;
  fileName: string;
  contentType: string;
  title: string;
  language: LanguageCode;
};

const resourceTableDefinition: TableColumn<"title" | "language">[] = [
  {
    id: "title",
    accessor: "title",
    headerLabel: t("common.title"),
    sortable: false,
  },
  {
    id: "language",
    accessor: "language",
    visibleFrom: false,
  },
  {
    id: "download",
    headerLabel: t("common.download"),
    sortable: false,
  },
];
// const { languageOptions } = useWebSessionStore();

// const filterDefinition: FilterDefinition<string>[] = [
//   {
//     id: "language",
//     type: "select",
//     options: languageOptions.map((lang) => ({
//       label: lang.label,
//       value: lang.region,
//     })),
//     startingValue: locale.value,
//     label: t("common.language"),
//     hasDefault: false,
//   },
//   {
//     id: "title",
//     type: "search",
//     startingValue: "",
//     label: t("common.search"),
//   },
// ];

// const { filter, updateFilter } = useFilter([
//   { id: "language", value: locale.value },
//   { id: "title", value: "" },
// ]);

const {
  data,
  isFetching,
  error: listFetchingError,
} = useFetch<Resource[]>("/api/resourceName", {}).get().json<Resource[]>();

const categorisedResources = computed<ResourceTableRow[]>(() => {
  return data.value
    ?.reduce((acc, resource) => {
      const { Id, Language, FileName, ContentType } = resource;
      const title: string =
        parseStringToJSON(resource.Name)?.[Language] || resource.Name;
      const category = resource.ResourceCategoryId;
      if (!acc[category]) {
        const titleAsJson = parseStringToJSON(resource.ResourceCategoryName);
        acc[category] = {
          title: titleAsJson?.[locale.value] || titleAsJson?.["en-gb"] || "",
          id: category,
          language: "",
          subRows: [],
        };
      }
      acc[category].subRows.push({
        id: Id,
        language: Language,
        fileName: FileName,
        contentType: ContentType,
        title,
      });
      return acc;
    }, [])
    ?.filter((x) => x)
    .map((category) => {
      category.subRows = category.subRows.sort((a, b) =>
        a.title.localeCompare(b.title),
      );
      return category;
    });
});

const toastStore = useToastStore();

const downloadResource = async (resource: ResourceTableSubRow) => {
  console.log(resource);
  const { id, language, fileName } = resource;

  const { data: resourceBlob, statusCode } = await useFetch(
    `/api/resourceFile/${id}/${language}`,
  )
    .get()
    .blob();
  if (statusCode.value === 409)
    toastStore.addToast({
      header: t("error.could_not_download"),
      body: t("error.could_not_download_rights"),
    });
  else if (statusCode.value !== 200)
    toastStore.addToast({
      header: t("error.could_not_download"),
      body: t("error.reload_or_support"),
    });
  else if (resourceBlob.value) downloadFile(resourceBlob.value, fileName);
  trackMixPanelEvent("export", {
    context: "Resources",
    resourceId: id,
    fileName,
    language,
  });
};

const helpCenterLangByLanguageCode = computed(() => {
  const map = {
    "nl-nl": "nl_NL",
    "nl-be": "nl_NL",
    "de-de": "de",
    "de-at": "de",
    "de-ch": "de",
    "es-es": "es",
    "es-mx": "es_MX",
    "es-ar": "es",
    "it-it": "it",
    "pt-pt": "pt_PT",
    "pt-br": "pt_BR",
  };
  return map[locale.value] ?? "en_US";
});
</script>
<template>
  <a
    target="_blank"
    class="mb-4 block"
    data-testid="help-center-link"
    :href="`https://goodhabitz.my.site.com/helpcenter/s/?language=${helpCenterLangByLanguageCode}`"
  >
    <GCard class="h-20 w-full">
      <GButton>{{ t("resources.help_center") }}</GButton>
    </GCard>
  </a>
  <GCard>
    <GStateHandlerWrapper :isLoading="isFetching" :error="listFetchingError">
      <template v-if="categorisedResources">
        <GTable
          :data="categorisedResources"
          :columns="resourceTableDefinition"
          :selectable="false"
          :is-expanded="true"
        >
          <template v-slot:download="cellProps">
            <GButton
              v-if="!cellProps.row.subRows"
              @click="downloadResource(cellProps.row)"
              size="small"
            >
              <template #prefixIcon>
                <ArrowDownToLineIcon class="h-4 w-4"
              /></template>
              {{ t("common.download") }}
            </GButton>
          </template>
        </GTable>
      </template>
    </GStateHandlerWrapper>
  </GCard>
</template>
